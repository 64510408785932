import Api from './ApiProvider';

export interface Message {
  id?: string;
  direction: 'incoming' | 'outgoing';
  type: 'CHOOSE_ONE' | 'TEXT';
  content: string;
  options?: string[];
  category?: string;
  ratable?: boolean;
  useful?: boolean;
}

export interface MagicAiStatusResponse {
  isAvailable: boolean;
  messages: Message[];
  usage: {
    creditsLimit: number;
    creditsUsed: number;
  };
}

export const getAiAssistantStatus = () => {
  return Api.get('v2/store/assistant/status').json<MagicAiStatusResponse>();
};

export const getAiAssistantQuotas = () => {
  return Api.get('v2/store/assistant/quotas').json<{ minSQRv: number; monthCredits: number }[]>();
};

export const getAiAssistantHistory = () => {
  return Api.get('v2/store/assistant/usage-history').json<
    { date: string; creditsUsed: number }[]
  >();
};
